<template>
  <h1>Kontakt</h1>

  <strong>peter milde + partner</strong><br />
  <strong>strategy consulting</strong><br />
  Unterau 14<br />
  D-82444 Schlehdorf am Kochelsee / Oberbayern<br />
  <br />
  Fon: + 49 (0) 8851 / 92 55 - 0<br />
  Fax: + 49 (0) 8851 / 92 55 – 44<br />
  eMail: mail@pmp-sc.com<br />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Kontakt',
  components: {},
})
</script>
